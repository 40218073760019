<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <b-col cols="12">
                    <ValidationProvider name="type" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('type')">
                            <constant-type-selectbox v-model="formData.type"
                                                     :validate-error="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12">
                    <ValidationProvider name="value" rules="required" v-slot="{valid, errors}">
                        <b-form-group v-if="formData.type != null"
                                      :label="$t('value')"
                        >
                            <div v-if="formData.type=='string'">
                                <b-form-input type="text" v-model="formData.value"
                                              :state="errors[0] ? false : null"/>
                            </div>
                            <div v-else-if="formData.type=='int'">
                                <b-form-input type="number" v-model="formData.value"
                                              :state="errors[0] ? false : null"/>
                            </div>
                            <div v-else-if="formData.type=='comma'">
                                <b-form-textarea v-model="formData.value"
                                                 rows="3"
                                                 max-rows="6"
                                />
                            </div>
                            <div v-else-if="formData.type=='json'">
                                <b-form-textarea v-model="formData.value"
                                                 rows="3"
                                                 max-rows="6"
                                />
                            </div>
                            <div v-else-if="formData.type=='bool'">
                                <b-form-select v-model="formData.value"
                                               :options="boolOptions"/>
                            </div>
                            <div v-else-if="formData.type=='date'">
                                <select-date v-model="formData.value"
                                             :validation-error="errors[0]"
                                             :is-time="true"
                                />
                            </div>
                            <div v-else-if="formData.type=='semester'">
                                <semesters-selectbox v-model="formData.value"
                                                     :validate-error="errors[0]"/>
                            </div>
                            <div v-else-if="formData.type=='html'">
                                <ckeditor v-model="formData.value"/>
                            </div>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
            </b-row>
            <div class="d-flex justify-content-center mt-2">
                <b-button variant="primary"
                          @click="updateForm"
                          :disabled="formLoading">
                    {{ $t('update') }}
                </b-button>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>

    // Services
    import ConstantService from '@/services/ConstantService';

    // Components
    import ConstantTypeSelectbox from '@/components/interactive-fields/ConstantTypeSelectbox';
    import SelectDate from '@/components/interactive-fields/SelectDate';
    import SemestersSelectbox from '@/components/interactive-fields/SemestersSelectbox';

    // Other
    import {ValidationObserver, ValidationProvider} from 'vee-validate'

    export default {
        components: {
            ConstantTypeSelectbox,
            SelectDate,
            SemestersSelectbox,

            ValidationProvider,
            ValidationObserver
        },
        props: {
            formId: {
                type: Number
            }
        },
        data() {
            return {
                formData: {
                    key: null,
                    value: null,
                    type: null
                },
                formLoading: false,

                boolOptions: [
                    {value: null, text: this.$t('enter_value'), disabled: true},
                    {value: 'true', text: this.$t('true')},
                    {value: 'false', text: this.$t('false')}
                ]
            }
        },
        watch: {
            formId: function(val) {
                this.get(val)
            }
        },
        async created() {
            await this.get(this.formId)
        },
        methods: {
            get(id) {
                ConstantService.get(id)
                               .then(response => {
                                   let data = response.data.data;

                                   this.formData = {
                                       key: data.key,
                                       value: data.value,
                                       type: data.type
                                   }
                               })
                               .catch(e => {
                                   this.showErrors(e)
                               })
            },

            async updateForm() {
                if (this.checkPermission('constant_update')) {
                    const isValid = await this.$refs.formModalValidate.validate();
                    if (isValid && this.formId) {
                        this.formLoading = true;
                        ConstantService.update(this.formData.key, this.formData)
                                       .then(response => {
                                           this.$emit('updateFormSuccess', true);
                                           this.$toast.success(this.$t('api.' + response.data.message));
                                       })
                                       .catch(e => {
                                           this.showErrors(e, this.$refs.formModalValidate);
                                       })
                                       .finally(() => {
                                           this.formLoading = false
                                       })
                    }
                }
            }
        }
    }
</script>
